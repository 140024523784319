.type-switcher {
  background: #0f2038;
  border-radius: 3px;
  height: 30px;
  display: flex;
  overflow: hidden;
  > .switcher-item {
    cursor: pointer;
    font-size: 13px;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    opacity: 0.7;

    svg {
      margin-right: 4px;
    }

    &.active {
      background: $paradigmLightBlue;
      color: #063b79;
      opacity: 1;
    }
  }
}

.contacts-list {
  &__online-status {
    @include same-size(7px);
    position: absolute;
    top: 8px;
    left: 5px;
    margin-right: 10px;
    border-radius: 50%;
    background-color: $lima;
  }

  &__away-status {
    display: flex;
    justify-content: center;
    border-radius: 50%;
    background-color: $golden;
  }

  &__invisible-status {
    display: flex;
    justify-content: center;
    border-radius: 50%;
    background-color: $grey-color;
  }

  &-wrapper {
    overflow-y: auto;
    overflow-y: overlay;
    overflow-x: hidden;
    visibility: hidden;
    transition: visibility 0.2s;

    &:hover {
      transition: visibility 0s 0.2s;
    }

    &:hover,
    &:focus {
      visibility: visible;
    }
  }
}
