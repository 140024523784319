.counterparties-container {
  width: 305px;
  flex: none;
  border: 1px solid #08182a;
  border-radius: 0 0 0 4px;
  background: #08182a;
  position: relative;

  &.invalid {
    border: 1px solid $ripeLemon;
    border-radius: 0 0 0 7px;
  }

  .cp-list {
    flex: 1;
    border-top: 1px solid rgba(255, 255, 255, 0.05);
    position: relative;
    .cp-item {
      border-bottom: 1px solid rgba(255, 255, 255, 0.05);
    }
  }

  .buttons-container {
    > .pill-button:not(:first-child) {
      margin-left: 0.5rem;
    }

    .pill-button {
      padding: 3.5px 16px;
      font-size: 13px;
      line-height: 1;
      border-radius: 11px;
      color: white;
      font-weight: bold;
      background: transparent;
      border: 1px solid rgba(126, 171, 236, 0.3);
      outline: none;

      &:disabled {
        opacity: 0.5;
      }

      &.selected-all {
        background-color: $paradigmLightBlue;
      }

      &.clear {
        font-weight: normal;
        border-color: transparent;
        padding-right: 0;
        color: #72adf2;
      }
    }
  }

  .approved-cp-search {
    height: 34px;
    width: 274px;
    border-radius: 17px;
    background-color: #0f2038 !important;
  }

  .error-counterparties {
    height: 37px;
    width: 303px;
    position: absolute;
    padding-top: 6px;
    bottom: 0;
    right: 0;
    border-radius: 0 0 0 7px;
    background-color: $firefly;
    box-shadow: 0 -2px 6px 0 rgba(0, 0, 0, 0.5);
  }
}

.rfq-builder {
  position: relative;

  .min-height-mdrfq {
    min-height: 209px;
  }

  .csv-reader-input {
    width: 100px;
    position: absolute;
    top: 0;
    opacity: 0;
    cursor: pointer;
    overflow: hidden;

    input {
      cursor: pointer;
      width: 100px;
    }
  }

  .table-title {
    position: relative;
    top: 10px;
    font-size: 14px;
    font-weight: 500;
    opacity: 0.9;
    display: flex;
    align-items: center;
    margin-bottom: 24px;
  }

  .sub-account {
    background-color: $firefly;
    border-radius: 7px;
    font-size: 13px;

    label {
      opacity: 0.5;
      margin-bottom: 2px;
    }
  }

  & > .inputs > div {
    flex-basis: 50%;
  }

  .single-select {
    display: flex;
    margin: -16px;

    &.command {
      display: flex;
      flex-wrap: wrap;
    }

    &.strategy {
      display: flex;
      flex-wrap: wrap;
    }

    & .item {
      cursor: pointer;
      border-radius: 3px;
      padding: 4px 16px;
      background-color: $firefly;
      text-align: center;
      font-size: 14px;

      &.active {
        color: $paradigmBlue;
        background-color: $paradigmLightBlue;
      }
    }
  }

  .table-container {
    min-height: 260px;
    background-color: #0d1c32;
    border-radius: 7px;
    &.anonymous {
      background-color: rgba(5, 21, 36, 0.3);
    }
    table {
      color: white;
      font-size: 13px;
      margin-bottom: 0px;

      td.compact {
        padding-left: 0;
        padding-right: 0;
      }

      thead {
        td {
          border-top: none;
          border-bottom: 1px solid #384457;
        }
      }

      tbody {
        tr {
          &:hover {
            color: white;
          }
        }

        td {
          padding: 0.7rem 0.5rem;
        }
      }

      .empty-holder {
        &.smaller {
          width: 40px;
        }

        &.submitted {
          color: $ripeLemon;
          opacity: 1;
        }

        width: 46px;

        &.quantity {
          width: 60px;
        }
      }

      input:not(:disabled):focus.quantity-input {
        box-shadow: 0 1px 0 0 $paradigmLightBlue;
      }

      .quantity-input {
        width: 46px;

        &.smaller {
          width: 40px;
        }

        &.quantity {
          width: 60px;
        }

        &::placeholder {
          color: #fff;
          opacity: 1;
          font-size: 13px;
        }
        &:focus::placeholder {
          color: transparent;
        }
        &.input-invalid {
          &,
          &::placeholder {
            color: $ripeLemon;
          }
          &:focus::placeholder {
            color: transparent;
          }
        }

        &.sub {
          font-size: 9px;
          position: absolute;
          left: 0;
          bottom: -15px;
          width: 66px;
        }

        &.future {
          width: 60px;
        }

        background-color: transparent;
        border: none;

        &.error {
          box-shadow: 0 1px 0 0 $maroonFlush;
        }

        outline: none;
        color: white;
        font-size: 12px;
        padding: 0 4px;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }
    }
  }
}

.rfq-warning-modal {
  .modal-content {
    padding: 32px;
    border: 1px solid $maroonFlush;

    i {
      color: #72adf2;
    }
  }

  .modal-title {
    font-weight: bold;
    font-size: 20px;

    .error {
      color: $maroonFlush;
      padding: 0 8px 0 0;
    }
  }
}
