.text-message {
  color: $nepal;
  font-size: $base-font;
}

.text-no-code {
  color: #aed7e8;
  font-size: 14px;
}

.anchor-code {
  text-decoration: underline;
  color: #aed7e8;
}
