// Paradigm Blues
$paradigmBlue: #0e69ee;
$paradigmLightBlue: #7fabec;

// Dark shades
$firefly: #091523;
$mirage: #182332;
$darkBlueContrast: #030a14;
$pickledBluewood: #27364c;
$blackTangaroa: #020c17;

// Warning color
$ripeLemon: #f1e20d;

// Text colors
$nepal: #97b2bd;

// Action colors (Sell/Buy)
$razzmatazz: #ea135e;
$lima: #7ed321;

// Inner notifications
$wildWatermelon: #fe5c83;

// Profile picture colors
$midnightBlue: #003366;
$seaGreen: #339966;
$fountainBlue: #68a2bf;
$maroonFlush: #bd264a;
$studio: #664bad;
$fireBush: #e08f39;
$mauve: #cc99ff;
$frenchRose: #f6597f;
