.member {
  padding: 25px;
  width: 339px;
  min-width: 339px;
  height: 100vh;
  background: $firefly;

  &__label {
    color: #fff;
    font-size: $tiny-font;
  }

  &__selected-label {
    color: #cdcdcd;
    font-size: $xs-font;
  }

  &__admin-btn {
    border: none;
    border-radius: 3px;
    background-color: $paradigmLightBlue;
    cursor: pointer;
    padding: 0;
    width: 48px;
    height: 18px;
    color: #fff;
    font-size: $tiny-font;
  }

  &__admin-btn--off {
    opacity: 0.25;
  }
}

.group {
  padding: 5px;

  &__input {
    border: none;
    border-radius: 2px;
    background-color: rgba(255, 255, 255, 0.1) !important;
    width: 100%;
    height: 31px;
  }

  &__starred {
    color: $golden;
    font-size: 19px !important;
  }
}

.create-group-modal {
  width: 400px;

  .error {
    color: $maroonFlush;
    font-size: 12px;
  }

  .modal-content {
    margin: 15vh auto 0;
    width: 400px;
    padding-top: 32px;
    padding-bottom: 24px;
    background-color: #0f2038 !important;
    box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.3);
  }

  .close-icon {
    color: $paradigmLightBlue;
    cursor: pointer;
  }

  .modal-title {
    font-size: 20px;
    font-weight: bold;
  }

  label {
    font-size: 13px;
    opacity: 0.5;
    padding-left: 2px;
    margin-bottom: 4px;
  }

  .divider {
    height: 1px;
    background: white;
    opacity: 0.05;
  }
  .button-divider {
    height: 1px;
    background: white;
    opacity: 0.2;
  }

  .user-search-item {
    display: flex;
    align-items: center;

    .name {
      flex: 1;
      margin-right: 12px;
      margin-left: 12px;
      font-size: 13px;
    }
    .status {
      color: $paradigmLightBlue;
    }
  }

  .matches-list {
    min-height: 200px;
    max-height: 250px;
    overflow: overlay;
  }

  .chip-list {
    padding-top: 16px;
    background: #132641;
    padding-bottom: 22px;
    margin-bottom: -24px;

    .chips-container {
      min-height: 100px;
    }

    .user-chip-item {
      margin-bottom: 6px;
      &:not(:last-child) {
        margin-right: 12px;
      }
    }
  }
}

.create-trading-channel-modal {
  width: 400px;

  .modal-content {
    margin: 15vh auto 0;
    width: 400px;
    padding-top: 32px;
    padding-bottom: 24px;
    background-color: #0f2038 !important;
    box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.3);
  }

  .close-icon {
    color: $paradigmLightBlue;
    cursor: pointer;
  }

  .modal-title {
    font-size: 20px;
    font-weight: bold;
  }

  .divider {
    height: 1px;
    background: white;
    opacity: 0.05;
  }

  .matches-list {
    min-height: 300px;
    max-height: 350px;
    overflow: overlay;
  }

  .counterparty-search-item {
    display: flex;
    align-items: center;

    .name {
      flex: 1;
      margin-right: 12px;
      margin-left: 12px;
      font-size: 13px;
      .ticker {
        opacity: 0.5;
        margin-left: 4px;
      }
      .admin {
        font-weight: 300;
        font-size: 12px;
        color: $nepal;
      }
    }
    .status {
      color: $paradigmLightBlue;
    }
  }
}
