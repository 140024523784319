.titlebar {
  .window-appicon {
    left: calc(50% - 110px);
  }
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}
