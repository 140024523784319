.error-popup {
  &__icon {
    font-size: 50px !important;
    color: #fff;
  }

  &__container {
    border-radius: 0.25rem;
    background-image: radial-gradient(circle, #19508f, #123358);
    padding: 10px;
    color: #fff;
    width: 400px;
    max-height: 200px;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    text-align: center;
    &.custom {
      max-height: none;
      min-height: none;
    }
  }

  &__btn {
    @include different-size(76px, 30px);
    border-radius: 18px;
    background-color: #123358;
    padding: 0;
    color: #fff;
    text-align: center;
    font-size: $tiny-font;
    font-weight: bold;
  }

  &__btn:focus {
    border: solid 1.5px #5a99f8;
  }
}
