.user-search {
  &__pl-small {
    padding-left: 0.455rem !important;
  }
}

.blinking_indicator {
  animation: blinkingBg infinite;
}

@keyframes blinkingBg {
  0% {
    background: rgba(117, 171, 242, 0.35);
  }
  49% {
    background: rgba(117, 171, 242, 0.35);
  }
  50% {
    background: rgba(117, 171, 242, 0.25);
  }
  99% {
    background: rgba(63, 91, 128, 0.2);
  }
  100% {
    background: rgba(117, 171, 242, 0.2);
  }
}

.search {
  &__plus-sm-icon {
    position: absolute;
    top: 5px;
    left: 2px;
    color: $blue-icon;
    font-size: 10px;
  }

  &__user-base-icon {
    position: absolute;
    top: 0;
    color: $blue-icon;
    font-size: 18px;
  }

  &__user-sm-icon {
    position: absolute;
    top: 2px;
    left: 16px;
    height: 24px;
    color: $blue-icon;
    font-size: 18px;
  }

  &__type {
    padding-left: 0.5rem !important;
    margin-bottom: 0.5rem !important;
    color: #fff;
  }
}
