.options {
  &-button {
    line-height: 1;
    height: 32px;
  }

  &-input {
    border-radius: 20px;
    border: none;
    width: 278px;
    text-indent: 20px;
    color: white;
    background-color: #23374e;
    padding: 0.475rem 0.75rem;
    &::placeholder {
      color: white;
      font-weight: 300;
    }

    &-wrapper {
      position: absolute;
      left: 6px;
      top: 8px;
      font-size: 20px;
      color: white;
      z-index: 9;
    }
  }

  .fetching-messages-ellipsis {
    bottom: 80px;
  }

  &-table {
    flex: 1;
    font-size: 14px;
  }

  .item {
    &-data {
      display: inline-block;
      position: relative;
      height: 34px;
      color: rgba(255, 255, 255, 0.7);
    }

    &.checkbox {
      .item-checkbox {
        height: 34px;

        & + .divider {
          width: 80%;
          position: relative;
          left: 20%;
        }
      }
    }

    & > .divider {
      height: 2px;
      background: $paradigmLightBlue;
      width: 100%;
      opacity: 0.1;
      margin: 4px 0;
      padding: 0;
    }

    & > div:not(.divider) {
      padding: 5px 16px;

      &.handle {
        position: sticky;
        background-color: #0d2440;
        z-index: 1;
        top: 0;
        color: $second-user-color;
        border-top: 1px solid rgba(255, 255, 255, 0.1);
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      }
    }
  }
}
