// Styles
.icon-desktop {
  position: relative;
  font-size: 18px;
}

.material-icons {
  font-size: 24px;
}

.icon-list-ul {
  position: absolute;
  top: 2px;
  left: 31px;
  font-size: 10px;
}

.form-check-label {
  margin-bottom: 8px;
  margin-left: 7px;
}

.notifications-box {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.tiny-msg {
  margin-left: 55px;
  font-size: $tiny-font;
}

.tertiary-msg {
  opacity: 0.5;
  margin-left: 15px;
  color: #fff;
  font-size: $tiny-font;
  font-style: italic;
}

.phone-input-size {
  padding: 2.5px 12px;
}

.minus-mt-2rem {
  margin-top: -2rem !important;
}

.inner-check {
  display: flex;
  flex-direction: column;
  margin-left: 55px !important;
}

.userUpdateSpacing {
  margin-bottom: 1rem;
}

.passwordChangeSpacing {
  margin-top: -2rem;
}

.checkbox-with-input {
  margin-right: 5px;
}
