.md-rfq {
  display: flex;
  flex-direction: column;
  width: 100%;

  .spinner {
    width: 40px;
    opacity: 0.9;
  }
  .mdrfq-timer {
    position: relative;
    border-radius: 3px;
    width: 180px;
    .hidden {
      display: none;
    }
  }
  .mdrfq-progress {
    height: 42px;
    background-color: $light-blue-2;
    position: absolute;
    transform-origin: left center;
  }
  .flash-animation {
    height: 42px;
    position: absolute;
    width: 180px;
    animation: flash 1s;
    animation-fill-mode: 'forwards';
    background-color: #3b77d4;
    opacity: 0;
  }

  @keyframes flash {
    0% {
      opacity: 1;
    }
    33% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  .mdrfq-box {
    width: 180px;
    display: flex;
    background: $dark-blue;
    height: 42px;
    padding: 3px 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .mdrfq-quotes-divider {
    width: 20px;
    height: 10px;
  }
  .md-rfq-header {
    display: flex;
    align-items: center;
    margin: 24px 0;
    padding-right: 24px;
    padding-left: 24px;
    .md-rfq-button {
      height: 30px;
      width: 80px;
      border-radius: 18px;
      background-color: $paradigmLightBlue;
      text-align: center;
      color: #fff;
      font-size: $small-font;
      border: none;
      outline: none;
    }
    .md-rfq-header-refresh {
      height: 30px;
      width: 100px;
      color: $paradigmLightBlue;
      border-radius: 20px;
      background-color: #041220;
      border-color: transparent;
      margin-top: 5px;
      font-size: 13px;
      font-weight: 500;
      &:disabled {
        opacity: 0.5;
      }
    }
    .md-rfq-header-kill-actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex: 1;

      .md-rfq-header-actions-item {
        background: transparent;
        color: white;
        border: transparent;

        &:not(:last-child) {
          margin-right: 24px;
        }
      }

      .md-rfq-header-actions-item:hover {
        opacity: 1 !important;
      }
    }
    .md-rfq-header-actions {
      height: 40px;
      background: #09213a;
      padding: 0 12px;
      border-radius: 3px;
      margin-left: 16px;
      display: flex;
      width: 100%;
    }
  }

  .md-rfq-content {
    flex: 1;
  }

  .md-rfq-table {
    max-width: calc(100vw - 60px);
    position: relative;

    .empty-list {
      position: absolute;
      top: 60px;
      width: 100%;
      text-align: center;
      opacity: 0.6;
    }

    .md-rfq-table-item {
      flex: 1;

      &:not(:first-child):not(:last-child) {
        .md-rfq-table-item-body > div,
        .md-rfq-table-item-header > div {
          padding-left: 8px;
          padding-right: 8px;
        }
      }

      &:first-child {
        .md-rfq-table-item-header,
        .md-rfq-table-item-body > div {
          padding-left: 24px;
        }
      }

      &:last-child {
        .md-rfq-table-item-header,
        .md-rfq-table-item-body > div {
          padding-right: 24px;
        }
      }

      .md-rfq-table-item-header {
        position: sticky;
        top: 0;
        z-index: 2;
        color: #72adf2;
        font-size: 14px;
        height: 40px;
        background: #041d39;
        display: flex;
        align-items: center;

        &:not(.disabled-drag) .drag-handle {
          letter-spacing: -1px;
          margin-right: 5px;
        }

        &.disabled-drag {
          color: white;
        }
      }

      .md-rfq-table-item-body {
        font-weight: 400;
        background: $firefly;

        & > div {
          height: 44px;
          border-bottom: 1px solid $pickledBluewood;
          display: flex;
          align-items: center;
        }
        & > div:first-child {
          border-top: 1px solid $pickledBluewood;
        }

        .divider {
          border-bottom: 1px solid rgba(39, 54, 76, 0.7);
        }

        .description {
          line-height: 16px;
        }
        .trade-description {
          color: rgba(255, 255, 255, 0.6);
        }

        .status {
          border-radius: 3px;
          text-transform: capitalize;
          display: flex;
          justify-content: center;
          padding: 0 4px;
          opacity: 0.6;

          &.Active {
            background: $lima;
            opacity: 1;
            height: 20px;
          }
          &.Pending {
            border: 1px solid $ripeLemon;
            opacity: 1;
          }
          &.Rejected {
            border: 1px solid $maroonFlush;
            opacity: 0.7;
          }
        }

        .actions:not(.details-actions) {
          background: $firefly;

          .mdrfq-action {
            @include css-arrow(
              3px,
              1px,
              #09192b,
              rgba(255, 255, 255, 0.3),
              bottom
            );

            &::before,
            &::after {
              top: 103%;
              transform: scaleY(1);
              z-index: 1;
            }
          }
        }

        .extended {
          background: #112338;

          .mdrfq-action {
            height: 40px !important;
          }

          &::before,
          &::after {
            box-sizing: unset;
          }

          .actions:not(.details-actions) {
            .mdrfq-action {
              height: 42px;
              @include css-arrow(
                3px,
                1px,
                #09192b,
                rgba(255, 255, 255, 0.3),
                top
              );

              &::before,
              &::after {
                top: auto;
                bottom: -2.2%;
                transform: scaleY(1);
              }
            }
          }
        }

        .bbo-container {
          display: flex;
          flex-direction: row;
          height: 44px;
          width: 534px;
          font-family: 'IBM Plex Sans';
          opacity: 0.7;
          font-size: 12px;
          font-weight: 500;
          border-radius: 0 0 3px 3px;
          background-color: $pickledBluewood;
          margin-top: 4px;

          .title-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
          }
          .title {
            margin-left: 8px;
            margin-right: 43px;
          }
          .sub-title {
            margin-left: 8px;
            opacity: 0.5;
            color: #ffffff;
            font-size: 10px;
            font-weight: bold;
          }

          .quote {
            width: 180px;
            height: 28px;
            background-color: #091b32;
            display: flex;
            margin-top: 8px;
          }

          .bid {
            justify-content: flex-start;
          }

          .offer {
            justify-content: flex-end;
          }

          .amount {
            min-width: 54px;
            margin: 7px 0px 7px 0px;
            text-align: center;
          }
          .price {
            width: 70px;
            padding: 7px 5.5px;
            text-align: center;
          }
          .divider {
            height: 1px;
            width: 19.5px;
          }
        }

        .secondary {
          margin: 8px 0;
        }

        .mdrfq-action {
          display: flex;
          padding: 4px 8px;
          align-items: center;
          font-size: 12px;
          height: 42px;

          .bbo-offset {
            opacity: 0.5;
            color: #ffffff;
            font-family: 'IBM Plex Sans';
            font-size: 10px;
            font-weight: bold;
          }

          .mdrfq-firm-name-container {
            width: 69px;
          }
          .mdrfq-action-firm-name {
            color: $paradigmLightBlue;
          }

          .mdrfq-action-box-bbo {
            text-align: center;
            margin-top: 2px;
            margin-bottom: 0.5px;
            opacity: 0.4;
            color: #ffffff;
            font-family: 'IBM Plex Sans';
            font-size: 10px;
            font-weight: bold;
          }

          .mdrfq-action-box {
            height: 35px;
            padding: 4px 0;
            width: 380px;
            display: flex;
            align-items: center;

            &.maker {
              border-left: 2px solid white;
            }
            &.empty {
              padding: 3px 8px;
              height: 42px;
              background: $dark-blue;
            }

            .mdrfq-action-box-time {
              background: #23374e;
              padding: 1px 4px;
              width: 40px;
              display: flex;
              justify-content: center;

              &.empty {
                padding: 0;
              }
            }

            .mdrfq-quote-placeholder {
              width: 165px;
            }

            .mdrfq-action-box-quote {
              opacity: 0.9;
              font-size: 13px;
              background: black;
              border-radius: 3px;
              font-weight: 500;
              padding: 1px 4px 1px 4px;
              width: 70px;
              display: flex;
              justify-content: center;

              &.no-quote {
                margin: auto;
              }

              &.empty {
                background: transparent;
              }

              &.bid {
                color: $razzmatazz;
              }

              &.ask {
                color: $lima;
              }
            }

            .mdrfq-action-box-percent {
              opacity: 0.7;
              min-width: 41px;
              text-align: center;
            }

            .mdrfq-action-box-button {
              opacity: 0.9;
              font-size: 13px;
              background: #23374e;
              padding: 1px 4px;
              border-radius: 99px;
              border: none;
              outline: none;
              width: 40px;
              text-align: center;

              &.sell,
              &.buy,
              &.kill {
                background-color: $darkBlueContrast;
              }

              &.kill {
                color: $paradigmLightBlue;
              }

              &.quote {
                color: $paradigmLightBlue;
                background: $pickledBluewood;
                width: 64px;
                margin: auto;
              }

              &.empty {
                padding: 0;
                background: none;
              }
            }
          }
        }

        .md-rfq-table-item-body-details {
          background: #112338;
          align-items: flex-start;

          .expanded-id-field {
            overflow: overlay;
            white-space: pre;
          }

          .actions-container {
            display: flex;
            flex-direction: column;
          }

          .actions {
            background: $firefly;
            border-radius: 0 0 3px 3px;

            &.transparent {
              background: transparent;
            }
          }

          .view-details {
            padding: 4px 0;
            color: $paradigmLightBlue;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 12px;
          }

          .placeholder {
            width: 535px;
            height: 35px;
            background: $firefly;
            border-radius: 0 0 3px 3px;
          }
        }
      }
    }
  }
}

.md-rfq-modal-header {
  background: rgba(0, 0, 0, 0.2);
  padding: 1rem 32px 1rem 32px;
  height: 60px;
  display: flex;
  flex: 0 0 auto;
  flex-grow: 1;
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;

  .header-title {
    white-space: pre-line;
    font-size: 12px;
    width: 200px;
  }

  .close-icon {
    color: $paradigmLightBlue;
    cursor: pointer;
    height: 16px;
    width: 16px;
  }
  .title {
    font-size: 20px;
    font-weight: bold;
  }
}

.md-rfq-header-icon {
  padding: 8px;
  background: #0a1d33;
  border-radius: 50%;
  height: 41px;
  left: calc(50% - 18px);
  top: -20px;
  color: $maroonFlush;

  .prices {
    color: rgb(241, 226, 13);
  }
}

.md-rfq-modal-table {
  margin-top: 1.5rem;

  .table-container {
    border-radius: 0.25rem;
    background: rgb(39, 54, 76);
    padding-bottom: 0.5rem;

    &:first-child {
      flex: 1 1 0%;
    }

    &:not(:first-child) {
      margin-left: 0.25rem;
    }

    &.highlighted {
      box-shadow: rgb(241, 226, 13) 0px 0px 0px 1px;
    }
  }

  .table-title {
    padding: 10px;
  }
}

.sell {
  color: $razzmatazz;
}

.buy {
  color: $lima;
}

.wrap-two-line-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.md-rfq-counterparties {
  .close-icon {
    color: $paradigmLightBlue;
    cursor: pointer;
    height: 16px;
    width: 16px;
  }
}

.better-quote-modal {
  .modal-header {
    padding-top: 32px;
    padding-left: 32px;
    padding-right: 32px;

    .title {
      color: #ffffff;
      font-size: 20px;
      font-weight: bold;
      height: 24px;
    }
  }

  .modal-body {
    opacity: 0.7;
    color: #f5f5f5;
    font-size: 17px;
    padding-top: 8.5px;
    padding-left: 32px;
    padding-right: 32px;
  }

  .close-icon {
    color: $paradigmLightBlue;
    cursor: pointer;
    height: 16px;
    width: 16px;
    margin-top: 10px;
  }
}

.bbo-table-container {
  width: 100%;
  height: 64px;
  display: flex;
  margin-top: 16px;

  font-family: 'IBM Plex Sans';
  color: rgba(255, 255, 255, 0.7);
  font-size: 12px;
  font-weight: 500;
  border-radius: 3px;
  background-color: rgba($pickledBluewood, 0.7);

  .inner-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .title-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-bottom: 8px;
    line-height: 1.2;
  }
  .sub-title {
    margin-left: 8px;
    color: rgba(255, 255, 255, 0.5);
    font-size: 10px;
    font-weight: bold;
    margin-top: -7px;
  }
  .title {
    margin-left: 8px;
    margin-right: 43px;
    display: flex;
    align-items: center;
    height: 28px;
  }
  .quote {
    width: 180px;
    height: 28px;
    background-color: #091b32;
    display: flex;
  }
  .mt {
    margin-top: 6px;
  }
  .quote-header {
    display: flex;
    padding: 8px 0;
    height: 28px;
  }
  .amount {
    width: 80px;
    text-align: center;
  }
  .price {
    width: 100px;
    text-align: center;
  }
  .divider {
    height: 1px;
    width: 19.5px;
  }
}
