.grfq-rfq-row-main {
  &:hover {
    .agress-input-field:not(:disabled) {
      border-bottom: 1px solid $paradigmLightBlue;
    }
  }
}

.row-expanded {
  .agress-input-field:not(:disabled) {
    border-bottom: 1px solid $paradigmLightBlue;
  }
}
