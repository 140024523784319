.restricted-ip {
  &__header {
    height: 40px;
    background-color: #0d192d;

    & > div {
      background-color: #0f2038;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      position: relative;
      top: 20px;
    }
  }
  &__container {
    margin-top: 100px;
    background-color: #0f2038 !important;
    color: #fff;
    width: 450px;
    max-height: 260px;
    min-height: 260px;

    &__title {
      font-size: 20px;
    }
    &__text {
      font-size: 14px;
    }
  }

  &__btn {
    @include different-size(136px, 30px);
    border-radius: 15px;
    background-color: $paradigmLightBlue;
    padding: 0;
    color: #fff;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
  }
}
