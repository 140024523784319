.trading-buttons {
  padding: 8px;
  background: #091b31;
  user-select: none;

  // spacing on flex wrap
  .buttons-wrapper {
    margin-top: -6px;

    .trading-button {
      margin-top: 6px;
    }
  }

  .trading-button {
    padding: 6px 9px;
    width: 100px;
    background: $pickledBluewood;
    border-radius: 15px;
    font-size: 13px;
    cursor: pointer;
    font-weight: bold;
    color: $paradigmBlue;
    display: flex;
    align-items: center;
    justify-content: center;

    &.isDisabled {
      opacity: 0.5;
      cursor: default;
    }

    &.base {
      background: $paradigmLightBlue;
    }
  }
}
