.sign-up {
  .rrui__select__arrow {
    display: block;
    content: '';
    width: 5px;
    height: 5px;
    margin-top: 0;
    margin-left: 10px;
    border-style: solid;
    border-color: white;
    border-top-width: 0;
    border-bottom-width: 1px;
    border-left-width: 0;
    border-right-width: 1px;
    transform: rotate(45deg);
    opacity: 1;
  }

  label {
    opacity: 0.5;
    color: #ffffff;
    font-size: 0.75rem;
    margin-bottom: 7px;
  }

  &__title {
    font-size: 20px;
    font-weight: bold;
    color: white;
    margin-bottom: 0;
  }

  &__input {
    border-radius: 3px;
    color: #ffffff;
    background: $pickledBluewood;
    border-color: $pickledBluewood;
    font-size: 0.85rem;
    height: 40px;
    &:focus {
      background: $pickledBluewood;
    }
  }

  input::placeholder {
    color: white;
    font-weight: 500;
  }

  button {
    padding-top: 3px;
    padding-bottom: 3px;
    height: 30px;
  }
}

.ant-picker-footer {
  border-top: 1px solid rgba(255, 255, 255, 0.06) !important;
  display: none;
}

.ant-picker-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.06) !important;
}

.ant-picker-body {
  th {
    color: #ffffff;
    opacity: 0.3;
  }
}
