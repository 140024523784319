.accept-agreement {
  .website-button {
    color: $paradigmBlue;
  }

  .accept-button {
    background: $paradigmLightBlue;
    &:hover {
      color: white;
    }
  }
}
