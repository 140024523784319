@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;500;600;700&display=swap');

// Global
:root {
  —-app-height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
}

html,
body {
  padding: 0;
  margin: 0;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  height: #{var(--app-height)};
  background-color: $firefly;
  font-family: 'IBM Plex Sans', sans-serif;
}

%font-details {
  line-height: normal;
  font-style: normal;
  font-stretch: normal;
}

%input-color {
  border-color: rgba(255, 255, 255, 0.1);
  background-color: rgba(255, 255, 255, 0.1);
}

.firm-name {
  font-size: $medium-font;
  margin-bottom: 0;

  div {
    max-width: calc(100vw - 470px);
    @media screen and (max-width: 800px) {
      max-width: calc(100vw - 240px);
    }
    @extend .text-truncate;
  }
}

.highlight {
  padding: 0;
  background-color: transparent !important;
  font-weight: 800 !important;
}

.highlight-msg {
  @extend .highlight;
  color: yellow !important;
}

.highlight-result {
  @extend .highlight;
  color: $highlight-text !important;
}

.general-title {
  @include different-size(300px, 24px);
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: $large-font;
  font-weight: bold;
  @extend %font-details;
  letter-spacing: 0.4px;
  text-align: left;
  color: #fff;
}

.input-invalid {
  border-color: $invalid-color !important;
  box-shadow: 0 0 10px $invalid-color;
}

.feedback-invalid {
  padding-top: 5px;
  color: $invalid-color;
  font-size: $tiny-font;
}

.form-control {
  @extend %input-color;
  color: #fff;
  font-size: $small-font;

  &:focus {
    border-color: transparent;
    background-color: rgba(255, 255, 255, 0.1);
    color: #fff;
  }

  &:disabled {
    background-color: #6c757d;
  }
}

.form-control-no-focus {
  @extend %input-color;
  color: #fff;
  font-size: $smaller-font;
  position: relative;
  flex: 1 1 auto;
  margin-bottom: 0;
  padding: 0.375rem 0.75rem;
  outline: none;
}

.form-input {
  border-radius: 3px;
  color: #fff;
}
.form-control.big {
  height: calc(1.5em + 0.75rem + 7px);
}

.disabled {
  color: #6c757d !important;
}

.gradient-section {
  box-shadow: -3px 0 10px 0 rgba(0, 0, 0, 0.3);
  background: $gradient-color;
  width: 300px;
  min-width: 300px;
  flex: 0 0 auto;

  &.preferences {
    overflow-y: auto;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
}

.light-font {
  font-weight: 300 !important;
}

.label-margin {
  margin-top: 1em;
  margin-bottom: 0;
}

.bg-color {
  background-color: $firefly;
}

.small-font {
  font-size: $small-font;
}

.smaller-font {
  font-size: $smaller-font;
}

.badge-details {
  opacity: 0.65;
  margin-top: 2px;
  border: solid 0.5px rgba(255, 255, 255, 0.2);
  background-color: rgba(255, 255, 255, 0.1);
  padding-top: 3px;
  width: 28px;
  height: 17px;
}

.new-badge {
  padding-top: 6px;
}

.bottom-btn {
  margin: 0 20px 2em 0;
}

.no-style-btn {
  border: 0;
  background-color: transparent;
  cursor: pointer;
  color: #fff;

  &:hover,
  &:active,
  &:disabled {
    background: transparent !important;
    opacity: 1 !important;
  }
}

.no-style-btn:focus {
  outline-style: none;
  box-shadow: none !important;
}

.box {
  @include different-size(28px, 23px);
  position: relative;
  background-color: $field-color;
}

.search-input-group {
  background-color: #021933 !important;
  border-radius: 17px;
  input {
    font-size: 13px;
    height: 32px;
    padding-left: 8px;
    &::placeholder {
      color: white;
      font-weight: 300;
    }
    &:focus {
      background: transparent;
    }
  }
  .input-group-text {
    //padding-left: 8px;
    padding-right: 0;
    color: white;
  }
  .dropdown .input-group-append {
    height: 100%;
  }
  .input-group-text {
    background: transparent !important;
  }
  & * {
    box-shadow: none !important;
    background: transparent !important;
    border: none;
  }
  button:not([role='menuitem']) {
    display: flex;
    background: transparent;
    color: $paradigmLightBlue;
    &:hover {
      background: transparent;
      color: #73a7f0 !important;
    }
    &:active,
    &:focus {
      background-color: transparent !important;
    }
  }
}

.input-group-text {
  background-color: rgba(255, 255, 255, 0.1) !important;
  border: 0;
  text-align: left;
}

.input-group-append {
  margin-left: 0 !important;
  svg {
    margin: auto;
  }
}

.input-button {
  height: 35px !important;
}

.primary-btn-sm {
  @include different-size(76px, 24px);
  border-radius: 18px;
  background-color: $paradigmLightBlue;
  color: #fff;
  text-align: center;
  font-size: $tiny-font;
  padding: 0;
}

.primary-btn-md {
  @include different-size(175px, 30px);
  border-radius: 15px;
  background-color: $paradigmLightBlue;
  color: #fff;
  text-align: center;
  font-size: $tiny-font;
  padding: 0;
}

.primary-btn-lg {
  @include different-size(248px, 30px);
  border-radius: 15px;
  background-color: $razzmatazz;
  color: #fff;
  text-align: center;
  font-size: $tiny-font;
  padding: 0;
}

.cancel-trade-btn {
  @include different-size(76px, 24px);
  border-radius: 18px;
  background-color: $pickledBluewood;
  color: rgba(255, 255, 255, 0.7);
  text-align: center;
  font-size: $tiny-font;
  padding: 0;
}

.tertiary-button {
  @include different-size(64px, 36px);
  background-color: transparent;
  text-align: center;
  color: #fff;
  font-size: $small-font;
  &:hover {
    color: inherit;
  }

  &.trading {
    height: 24px;
    font-size: 13px;
    width: 90px;
    border-radius: 12px;
    line-height: 0.8;
  }
}

.cancel-trade-button {
  width: 108px;
  height: 30px;
  border-radius: 15px;
  background-color: $razzmatazz;
  color: #fff;
  text-align: center;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
}

.primary-button {
  @include different-size(99px, 35px);
  border-radius: 18px;
  background-color: $paradigmLightBlue;
  text-align: center;
  color: #fff;
  font-size: $small-font;

  &.sell {
    background-color: $razzmatazz;
  }

  &.buy {
    background-color: $lima;
  }

  &.trading {
    background-color: $pickledBluewood;
    color: $paradigmLightBlue;
    height: 24px;
    font-size: 13px;
    width: 90px;
    border-radius: 12px;
    line-height: 0.8;
  }

  &.padding {
    width: unset;
    height: unset;
    padding: 4px 18px;
  }
}

.danger-button {
  border-radius: 18px;
  background-color: $maroonFlush;
  text-align: center;
  color: #fff;
  font-size: $small-font;
  width: unset;
  height: unset;
  padding: 4px 18px;
}

.secondary-button {
  border-radius: 18px;
  background-color: $pickledBluewood;
  text-align: center;
  font-size: $small-font;
  color: white;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

.flex-center {
  display: flex;
  justify-content: center;
}

.flex-top-bottom {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.box-shadow:focus {
  outline: 0 !important;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25) !important;
}

.alert-danger {
  background-color: rgba(243, 214, 216, 0.89) !important;
}

.tiny-font {
  font-size: $tiny-font !important;
}

.contact-msg {
  align-self: center;
  margin-top: 16px;
  border-radius: 4px;
  padding-left: 120px;
  padding-right: 120px;
  font-weight: 300;
  padding-bottom: 26px;

  b {
    font-weight: 400;
  }

  .timestamp {
    text-align: center;
    font-size: 10px;
    margin-top: 10px;
    margin-bottom: 20px;
    opacity: 0.7;
  }
}

.tradebot-badge {
  width: 120px;
  height: 21px;
  border-radius: 25px;
  background-color: #222c39;
  font-size: $base-font;
  font-weight: 400;
}

.tradebot-bg {
  background-color: #0f2038;
}

.online-contact {
  @include same-size(7px);
  display: flex;
  justify-content: center;
  border-radius: 50%;
  background-color: $lima;
}

.half-field {
  border-radius: 2px;
  border-color: transparent;
  background-color: transparent;
  color: #fff;
}

.dropdown-btn {
  padding-left: 20px;
}

.dropdown-menu {
  background-color: #0f2038 !important;
  box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.3);
  border: none;

  button {
    opacity: 0.7;
    outline: none;
    box-shadow: none;
  }
}

.dropdown-item {
  color: #fff !important;
  font-size: 14px;
}

.dropdown-item:hover {
  background-color: #14365f !important;
}

.dropdown-item:focus {
  background-color: #07182c;
}

.disabled {
  color: #6c757d !important;
}

.group-input-bg {
  background-color: #021933 !important;
}

.search-input {
  border-color: $field-color;
  font-size: $msg-font;
  font-weight: 300;
  color: $chat-msg-color;
  background-color: $field-color !important;
  width: 180px !important;
  border: 0;
  height: 35px !important;
}

.search-border-left {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.search-border-right {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.search-input::placeholder {
  color: $chat-msg-color;
  font-size: $small-font;
  font-weight: 300;
}

.form-group {
  margin: 0;
}

.transparency-line {
  margin-bottom: 1rem;
  background: linear-gradient(
    to right,
    rgba(188, 188, 188, 0),
    rgba(188, 188, 188, 0.2),
    rgba(188, 188, 188, 0)
  );
  width: 100%;
  height: 2px;
}
.formPhoneInput {
  padding: 2.5px 12px;
}

.error {
  padding: 5px;

  &__icon-container {
    margin-right: 5px;
  }
}

.centerButtons {
  margin-top: 1.5rem;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.star-lg-icon {
  color: $golden;
  font-size: 14px;
}

.xs-icon {
  color: $grey-color;
  font-size: 12px;
}

.small-border {
  border-right: solid 1px $border-right;
  border-left: solid 1px $border-right;
}

.icon-normal-height {
  height: 24px;
}

// Network Error component
.blur {
  filter: blur(5px);
  user-select: none;
  border: none;
  pointer-events: none !important;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1040;
  overflow: hidden;
}

.overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 500px;
  z-index: 999999999;
  color: white;
  padding: 50px;

  .reconnecting {
    font-size: 1rem;
    opacity: 0.7;
  }

  h5 {
    margin: 0;
  }

  a {
    color: $paradigmLightBlue !important;
    margin-left: 8px;
    text-decoration: underline !important;
    font-size: 13px;
    cursor: pointer;
  }

  i {
    margin-left: 10px;
  }
}

.pointer:hover {
  cursor: pointer;
}

.cursor-default {
  cursor: default !important;
}

.greyout {
  filter: blur(1px) opacity(95%);
}

.tiny-color {
  color: $nepal;
}

.modal-content {
  background-color: $secondary-color;
  color: #fff !important;
  font-family: 'IBM Plex Sans', sans-serif !important;
  font-size: $small-font !important;
  overflow: auto;
  box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.5);
}

.paradigm-confirm-modal {
  width: 400px;

  .modal-content {
    margin: 15vh auto 0;
    width: 400px;
    padding-top: 32px;
    padding-bottom: 24px;
    background-color: #0f2038 !important;
    box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.3);
  }

  .close-icon {
    color: $paradigmLightBlue;
    cursor: pointer;
  }

  .modal-title {
    font-size: 20px;
    font-weight: bold;
  }
  .modal-body {
    font-size: 13px;
  }
}

.modal-dialog {
  margin-left: auto;
  margin-right: auto;
}

.modal-dialog:not(.custom-dialog) {
  width: 414px !important;
  overflow-y: initial !important;

  &.rfq-builder-modal {
    margin: 50px auto !important;

    &.modal-xl.xl {
      width: 695px !important;
      max-width: 695px !important;
      &.md {
        width: 970px !important;
        max-width: 970px !important;
      }
    }

    .modal-content {
      overflow: scroll;
      overflow: overlay;
    }
  }
}

.modal-header {
  border-bottom: none;
  align-items: center;
}

.modal-footer {
  border-top: none;
}

.modal-xl:not(.custom-dialog) {
  width: 614px !important;
  max-width: 614px !important;
  &.xl {
    width: 760px !important;
    max-width: 760px !important;
  }
}

.modal-header-close-button {
  padding-bottom: 0;
  padding-left: 32px;
  padding-top: 32px;

  .close {
    height: 14.14px;
    width: 14.14px;
    color: #72adf2;
    font-size: 30px;
    margin-bottom: 24px;
    margin-right: 5px;
    text-shadow: none;
  }
  .close,
  .close span:focus {
    outline: none;
  }
}

.kill-quote-title {
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
}

.kill-quote-message {
  opacity: 0.7;
  color: #f5f5f5;
  font-family: 'IBM Plex Sans';
  font-size: 17px;
  letter-spacing: 0;
  line-height: 20px;
  margin-left: 32px;
  margin-bottom: 39.5px;
  margin-top: 24.5px;
}

.opacity-5 {
  opacity: 0.5;
}

.opacity-3 {
  opacity: 0.3;
}

.opacity-6 {
  opacity: 0.6;
}

.opacity-7 {
  opacity: 0.7;
}

.opacity-full {
  opacity: 1;
}

// Scrollbar app styling
::-webkit-scrollbar-track {
  border-radius: 8px;
}

::-webkit-scrollbar {
  width: 8px;
  height: 10px;
  background-color: transparent;
  background-clip: padding-box;
  border: 8px solid transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  box-shadow: inset 0 0 0 8px;
  color: rgba(31, 71, 156, 0.356);
}

::-webkit-scrollbar-corner {
  background: rgba(0, 0, 0, 0);
}

.react-contextmenu {
  &--visible {
    background-color: #0f2038 !important;
    box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.3);
    color: #fff !important;
    font-size: 14px;
    z-index: 99999;
    min-width: 150px;
    cursor: pointer;
  }

  &-item--divider {
    @extend .transparency-line;
    margin-bottom: 0;
  }

  &-item:not(&-item--divider) {
    padding: 8px 16px;
    opacity: 0.7;

    &:hover {
      background-color: #14365f !important;
    }
  }
}

/* @szhsin/react-menu customization start */
.szh-menu {
  background-color: #0f2038;
  color: #ced5dd;
  border-radius: 0;
  padding: 0;
  min-width: 150px;
}

.szh-menu__item {
  padding: 8px 16px;
}

.szh-menu__item--hover {
  background-color: #102b4b;
}

.szh-menu__item--active {
  background-color: #14365f;
}
/* @szhsin/react-menu customization end */

.fetching-messages-ellipsis {
  display: inline-block;
  width: 64px;
  height: 12px;
  position: relative;
  left: calc(50% - 33px);
  flex-shrink: 0;
  z-index: 4;

  &.top {
    top: 4px;
  }
  &.bottom {
    bottom: 28px;
  }
  &.center {
    top: calc(50% - 32px);
  }
  &.pending-message-scroll {
    position: absolute;
    top: 14px;
  }
  &.getting-latest-messages {
    position: absolute;
    top: -45px;
  }

  div {
    position: absolute;
    top: 27px;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: #fff;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);

    &:nth-child(1) {
      left: 6px;
      animation: fetching-messages-ellipsis1 0.6s infinite;
    }
    &:nth-child(2) {
      left: 6px;
      animation: fetching-messages-ellipsis2 0.6s infinite;
    }
    &:nth-child(3) {
      left: 26px;
      animation: fetching-messages-ellipsis2 0.6s infinite;
    }
    &:nth-child(4) {
      left: 45px;
      animation: fetching-messages-ellipsis3 0.6s infinite;
    }
  }
}

@keyframes fetching-messages-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes fetching-messages-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes fetching-messages-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(19px, 0);
  }
}

.button-context-menu {
  transform: translateY(-55px);
  cursor: pointer;
}

.tooltip {
  font-size: 10px;

  .arrow::before {
    border-left-color: $firefly;
  }

  .tooltip-inner {
    &.darker {
      background-color: $msg-box;
    }
    color: $second-user-color;
    background-color: $firefly;
  }
}

progress[value] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  height: 5px;
  width: 100%;

  &::-webkit-progress-bar {
    background-color: transparent;
  }
  &::-webkit-progress-value {
    background: $paradigmLightBlue;
  }
}

.word-break {
  word-break: break-all;
}
.p-4-5 {
  padding: $spacer * 2 !important;
}
.px-4-5 {
  padding-left: $spacer * 2 !important;
  padding-right: $spacer * 2 !important;
}
.pb-4-5 {
  padding-bottom: $spacer * 2 !important;
}
.pb-2-3 {
  padding-bottom: 10px !important;
}
.mt-4-5 {
  margin-top: $spacer * 2 !important;
}

.user-chip-item {
  display: inline-flex;
  align-items: center;
  color: white;
  background: $pickledBluewood;
  border-radius: 16px;
  font-size: 14px;
  padding-right: 1em;

  .avatar {
    font-size: 10px;
  }

  .name {
    padding-right: 10px;
    padding-left: 10px;
  }

  svg {
    width: 7px;
    height: 7px;
  }
}

.px-1-2 {
  padding-left: 10px;
  padding-right: 10px;
}

.Linkify {
  width: 100%;
}

.modal-backdrop {
  background-color: rgba(9, 21, 35, 0.7);
  &.show {
    opacity: 1;
  }
}

.overflow-visible {
  overflow: visible;
}

.context-menu {
  border-radius: 3px;
  overflow: hidden;
}
input[type='file']::-webkit-file-upload-button {
  cursor: pointer;
}

.scrollable-table {
  tbody {
    display: block;
    width: 100%;
    overflow: overlay;

    tr {
      width: 100%;

      &:not(.top-divider) {
        min-height: 38px;
      }

      td {
        padding: 9px 12px 9px;
      }
    }
  }
  thead {
    width: 100%;
  }
  thead,
  tbody tr {
    display: table;
    //width: 100%;
    table-layout: fixed;
  }
}

.option-bot-state-item {
  display: flex;

  .details-trigger-container {
    margin-left: auto;
    margin-right: auto;
  }

  .rfq-container {
    justify-content: space-between;
    align-items: start !important;
  }

  .strategy-container {
    margin-bottom: 0.25rem;
    margin-right: 0.25rem;
    font-weight: 500 !important;
  }

  .rfq-title {
    margin-top: 2px;
    margin-right: 0.25rem;
    flex-shrink: 0;
  }

  @media screen and (max-width: 620px) {
    flex-direction: column;
    .rfq-container {
      margin-right: 0 !important;
      .mark-prices {
        display: block;
      }
    }

    .strategy {
      & > div {
        margin-right: 0 !important;
      }
    }
  }
  @media screen and (max-width: 910px) {
    tr {
      font-size: 11px !important;
      white-space: nowrap;
    }
    flex-direction: column;
  }
  @media screen and (max-width: 800px) {
    .strategy-container {
      width: 100%;
    }
    .details-trigger-container {
      margin-right: unset;
    }
  }
  @media screen and (min-width: 801px) and (max-width: 930px) {
    .rfq-container {
      margin-right: 0 !important;
      .mark-prices {
        display: block;
      }
    }

    .strategy {
      & > div {
        margin-right: 0 !important;
      }
    }
  }
  @media screen and (max-width: 508px) {
    .details-text {
      display: none;
    }
  }
  @media screen and (max-width: 430px) {
    .details-trigger {
      display: none;
    }
  }
}

@keyframes shrink-horizontal {
  to {
    transform: scaleX(0);
  }
}

.edit-default-btn {
  color: $paradigmLightBlue;
  padding: 0;
  background: transparent;
  border: 0;
}

// Toastify
.Toastify__toast-container {
  padding: 0;
  width: auto;

  @media (max-width: 600px) {
    width: 90%;
  }

  &.Toastify__toast-container--top-right,
  &.Toastify__toast-container--top-center {
    top: 2em;
    @media (max-width: 600px) {
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .Toastify__toast {
    box-shadow: none;
    min-height: auto;
  }

  .Toastify__toast {
    @media (max-width: 900px) {
      margin-bottom: 1rem;
    }
  }

  .Toastify__toast,
  .Toastify__toast-body {
    background: none;
    padding: 0;
    width: 100%;
  }
}

// Toastify - no animation
@keyframes withoutAnimation {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

.without-animation {
  animation: withoutAnimation 0.2s;
}

.recharts-cartesian-axis-line,
.recharts-cartesian-axis-tick-line {
  stroke: #2f6382;
  stroke-width: 2;
}
.recharts-wrapper .recharts-cartesian-grid-horizontal line:nth-last-child(2),
.recharts-wrapper .recharts-cartesian-grid-vertical line:last-child {
  stroke-opacity: 0;
}

.chart-modal {
  .modal-content {
    width: 221px;
    box-shadow: none;
    background-color: transparent !important;
    overflow: visible;
  }
  .modal-dialog {
    position: absolute;
  }
}
.chart-modal + .modal-backdrop.show {
  opacity: 0;
}

.szh-menu--state-closing {
  display: none;
}
