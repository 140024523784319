.message-bubble-container {
  position: relative;
  display: flex;
  width: 100%;

  .message-avatar {
    height: 30px;
    width: 30px;
    color: #ffffff;
    font-size: 12px;
    font-weight: 900;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: flex-end;
    margin-bottom: 5px;
    margin-right: 5px;
    z-index: 9;
    flex: 0 0 auto;
  }

  &.isOutgoing:not(.isCompact) {
    justify-content: flex-end;
  }

  .message-bubble {
    border-radius: 6px;
    padding: 6px 8px 4px 8px;
    margin-top: 3px;
    margin-bottom: 3px;
    display: inline-flex;
    min-width: 100px;
    max-width: 430px;

    &.last {
      margin-bottom: 6px;
    }
    &.isTrading {
      margin-top: 6px;
      max-width: 800px;
    }

    &.bordered {
      box-shadow: 0 0 0 1px #476591;

      &.last {
        &:before {
          box-shadow: 0 1px 0 0 #476591 !important;
        }

        &:after {
          box-shadow: inset -1px 0 0 0 #476591 !important;
        }
      }
    }

    &.isIncoming {
      color: white;
      background: $pickledBluewood;
      position: relative;

      &.isTrading {
        background: #0f2038;
        width: 100%;
        &.last:before {
          background: #0f2038;
        }
      }

      &:not(.showAvatar) {
        margin-left: 35px;
      }
      &.isPersonal:not(.isCompact) {
        margin-left: 0;
      }

      &.last:before {
        content: '';
        position: absolute;
        z-index: 0;
        bottom: 0;
        left: -6px;
        height: 8px;
        width: 10px;
        background: $pickledBluewood;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 4px;
        padding-right: 12px;
      }
      &.last:after {
        content: '';
        position: absolute;
        z-index: 1;
        bottom: 1px;
        left: -8px;
        width: 8px;
        height: 9px;
        background: $firefly;
        border-bottom-right-radius: 7px;
      }
    }

    &.isOutgoing {
      background: #274168;
      position: relative;

      &.isCompact {
        &:not(.showAvatar) {
          margin-left: 35px;
        }

        &.last:before {
          content: '';
          position: absolute;
          z-index: 0;
          bottom: 0;
          left: -6px;
          height: 8px;
          width: 10px;
          background: #274168;
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 4px;
          padding-right: 12px;
        }
        &.last:after {
          content: '';
          position: absolute;
          z-index: 1;
          bottom: 1px;
          left: -8px;
          width: 8px;
          height: 9px;
          background: $firefly;
          border-bottom-right-radius: 7px;
        }
      }

      &:not(.isCompact) {
        &.last:before {
          content: '';
          position: absolute;
          z-index: 0;
          bottom: 0;
          right: -5px;
          height: 8px;
          width: 12px;
          background: #274168;
          border-bottom-left-radius: 2px;
          border-bottom-right-radius: 4px;
        }
        &.last:after {
          content: '';
          position: absolute;
          z-index: 1;
          bottom: 1px;
          right: -8px;
          width: 8px;
          height: 9px;
          background: $firefly;
          border-bottom-left-radius: 8px;
        }
      }
    }
  }
}
