.get-started-btn {
  border-radius: 18px;
  background-color: #3b72b2;
  width: 198px;
  height: 35px;
  text-align: center;
  color: #fff;
  font-size: $small-font;
}

.welcome {
  &__msg {
    font-size: 26px;
  }
}
