$secondary-color: #0d2440;
$gradient-color: linear-gradient(#0f2b4c, #0a1c32);
$blue-icon: #73a7f0;
$dark-blue: #081d38;
$light-blue: #122a47;
$light-blue-2: #0f2d51;
$msg-box: #0d2440;
$golden: #e1ad51;
$grey-color: #bcbcbc;
$field-color: #021933;
$invalid-color: #dc3545;
$alert-color: #721c24;
$border-right: #222c39;
$chat-msg-color: #dee2e6;
$second-user-color: #75abf2;
$user-hover-color: rgba(53, 146, 255, 0.5);
$disabled-color: #21282f;
$disabled-font-color: #495057;
$blue: #5175a8;
$highlight-text: #75abf2;

$large-font: 20px;
$medium-font: 18px;
$base-font: 16px;
$small-font: 14px;
$smaller-font: 13px;
$tiny-font: 12px;
$xs-font: 10px;
$msg-font: 14.5px;
