.error-icon {
  color: $invalid-color;
  font-size: 15px !important;
}

.icon-detail {
  color: $blue-icon;
  font-size: 3rem;
}

.sm-icon {
  color: $blue-icon;
  font-size: 18px;
}

.medium-icon {
  color: $blue-icon;
  font-size: 20px;
}

.white-icon {
  color: #fff;
  font-size: 18px;
}

.icon-ban {
  color: #fff;
  font-size: 24px;
}

.normal-icon {
  color: $blue-icon;
  font-size: 24px;
}

.star-icon {
  color: $golden;
  font-size: 12px;
}

.svg-icon-user path {
  background-color: $blue-icon;
}

.icon-alert {
  color: $alert-color;
  font-size: 16px;
}

.clear-icon {
  color: #fff;
  font-size: 15px !important;
}

.search-clear-icon {
  color: $nepal;
  font-size: 20px !important;
  margin-top: 4px;
}

.medium-size-icon {
  color: $blue-icon;
  font-size: 20px;
}

.medium-large-size-icon {
  color: $blue-icon;
  font-size: 23px !important;
}

.small-size-icon {
  font-size: 16px !important;
}

.look-up-icon {
  margin-left: 3px;
  font-weight: 300;
  font-size: 13px;
}

.header-medium-icon {
  color: $blue-icon;
  font-size: 22px !important;
}

.header-ellipsis-icon {
  @extend .header-medium-icon;
  padding-top: 1px !important;
}

.hash-circle {
  &::after {
    content: '#';
    border: 0.5px solid white;
    font-weight: 500;
    border-radius: 50%;
    font-size: 11px;
    padding: 0 4px;
    margin-left: 8px;
    color: white;
    height: 16px;
    width: 16px;
    text-indent: 0.8px;
  }
}

.dollar-circle {
  &::after {
    content: '$';
    border: 0.5px solid $blue-icon;
    font-weight: 500;
    color: $blue-icon;
    border-radius: 50%;
    font-size: 11px;
    padding: 0 4px;
    margin-left: 8px;
    height: 16px;
    width: 16px;
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.spinning-circle {
  font-size: 10px;
  //margin: 50px auto;
  text-indent: -9999em;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  background: linear-gradient(
    to right,
    #ffffff 10%,
    rgba(255, 255, 255, 0) 42%
  );
  position: relative;
  animation: spin 1.4s infinite linear;
  transform: translateZ(0);
}
.spinning-circle:before {
  width: 50%;
  height: 50%;
  background: #ffffff;
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: '';
}
.spinning-circle:after {
  background: $firefly;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: '';
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
