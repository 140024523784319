.settings-modal {
  width: 400px;
  &.mobile {
    width: 100%;
    max-width: 100%;
    margin: 0;
    height: 100%;
    .modal-content {
      margin: 0;
      padding: 30px 0 0 0;
      height: 100%;
    }
  }
  .modal-content {
    margin: 15vh auto 0;
    overflow: visible;
    padding-top: 32px;
    padding-bottom: 24px;
    background-color: #0f2038 !important;
    box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.3);

    .stary-skies {
      position: absolute;
      z-index: 0;
      top: 0;
      left: 0;
      width: 100%;
      opacity: 0.5;
    }
  }

  .close-icon {
    color: $paradigmLightBlue;
    cursor: pointer;
  }

  .centered {
    margin-left: auto;
  }
  .modal-title {
    font-size: 20px;
    font-weight: 500;
  }
  .divider {
    height: 8px;
    opacity: 0.2;
    background-color: #aed7e8;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .divider-totp {
    height: 2px;
    background: linear-gradient(
      270deg,
      rgba(238, 238, 238, 0) 0%,
      rgba(216, 216, 216, 0.2) 53.67%,
      rgba(216, 216, 216, 0) 100%
    );
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .backup-codes {
    border-radius: 2px;
    background-color: $pickledBluewood;
    height: 153px;
    width: 335px;
    ul {
      padding: 0;
      margin: auto;
      list-style-type: none;
    }
    li {
      font-size: 12px;
    }
    .col-6:nth-child(odd) {
      text-align: right;
    }
  }
  .btn-deactivate {
    @include different-size(108px, 30px);
    border-radius: 15px;
    background-color: $razzmatazz;
    color: #fff;
    text-align: center;
    font-size: $tiny-font;
    padding: 0;
  }

  .btn-generate {
    @include different-size(96px, 30px);
    border-radius: 15px;
    background-color: $paradigmLightBlue;
    color: #fff;
    text-align: center;
    font-size: $tiny-font;
    padding: 0;
  }

  .settings-link {
    font-size: 17px;
    opacity: 0.7;
    background: transparent;
    border: none;
    &.isDisabled {
      opacity: 0.3;
      pointer-events: none;
      cursor: default;
    }

    &:not(:last-child) {
      margin-bottom: 16px;
    }

    color: #f5f5f5;
    padding: 2px 0px;
    display: flex;
    align-items: center;
    position: relative;
    transition: all 0.2s ease-in-out;

    &:hover {
      opacity: 1;
      text-decoration: none;
    }

    .link-icon {
      width: 2rem;
      text-align: center;
    }
    .link-label {
      padding-left: 0.75rem;
    }
  }

  input[type='password'] {
    height: 40px;
  }

  label {
    font-size: 13px;

    &:not(.switch) {
      opacity: 0.5;
    }

    padding-left: 2px;
    margin-bottom: 4px;
  }

  .settings-modal__chat-group {
    label {
      opacity: 1;
    }

    h4 {
      font-size: 17px;
    }
    .switch .slider.isBigger + .label-text {
      font-size: 15px;
      color: #f5f5f5;
    }
  }
  small.text-muted {
    line-height: 1.2;
    display: block;
    padding: 0.5rem 0 0;
  }
}

.settings-trigger {
  display: flex;
  color: $paradigmLightBlue;
  border: none;
  background: transparent;
}

.terminate-all-btn {
  color: $maroonFlush;
  background: transparent;
  border: 0;
}

.green-circle {
  background-color: $lima;
  border-radius: 50%;
  display: inline-block;
  margin-bottom: 3px;
  margin-right: 7px;
  width: 5px;
  height: 5px;
}
