html {
  font-size: 1rem;
}
.layout {
  &__body {
    margin: 0;
    padding: 0;
    height: 100%;
    color: #fff;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: $small-font;
    overflow: hidden;

    & > div.flex-end {
      height: 100%;
    }

    .main-container {
      &.full-mode > .gradient-section {
        flex: 1;
      }
      &:not(.full-mode).chat-mode > .sidebar {
        border-right: 1px solid rgb(41, 65, 96);
      }
    }
  }

  &__gradient {
    background-image: url('../assets/pattern-2.jpg');
    background-size: cover;
    height: 100%;
    overflow: auto;
  }

  &__container {
    padding: 32px;
    background: #091522;
    box-shadow: 0 7px 20px 0 rgba(0, 0, 0, 0.37);
    max-width: 404px;
    width: 90%;
    border-radius: 7px;
  }

  &__logo {
    display: flex;
    margin-top: 6vh;
    margin-bottom: 3vh;

    img {
      height: 75px;
      width: 75px;
    }

    &__text {
      font-size: 26px;
      font-weight: 600;
    }
  }
}

.progress-bar {
  position: absolute;
  width: 30%;
  left: 50%;
  top: 74%;
  transform: translateX(-50%) translateY(-50%);
  background-color: inherit;

  &__text {
    position: absolute;
    width: 30%;
    left: 50%;
    top: 70%;
    transform: translateX(-50%) translateY(-50%);
    color: #fff;
  }
}

$loading-icon-size: 170px;

.loading-screen {
  @extend .layout__gradient;
  width: 100%;
  display: flex;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@media screen and (max-width: 400px) {
  html {
    font-size: 0.875rem;
  }
}
