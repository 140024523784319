.blotter {
  position: relative;
  overflow: auto;

  .options {
    position: absolute;
    min-width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
  }

  .invisible-input {
    background: transparent;
    border: none;
    color: white;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    padding: 0 16px;

    &:active,
    &:focus {
      outline: none;
    }
  }

  .fetching-messages-ellipsis {
    bottom: 80px;
  }

  .item {
    &-data {
      display: inline-block;
      position: relative;
      height: 40px;
      color: rgba(255, 255, 255, 0.5);

      &.editable {
        cursor: pointer;
      }
    }

    &.checkbox {
      & > div {
        padding-left: 40px;
      }
    }

    & > div {
      padding: 8px 16px;

      &.handle {
        position: sticky;
        background-color: #0d2440;
        z-index: 1;
        top: 0;
        color: $second-user-color;
        border-top: 1px solid rgba(255, 255, 255, 0.1);
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      }
    }
  }
}
