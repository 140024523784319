.modal-popup {
  display: flex;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;
  width: 380px;
  height: 150px;
  border-radius: 0.25rem;
  background-image: linear-gradient(141deg, #19508f 0%, #123358 75%);
  padding: 25px;
  color: #fff;

  &__message {
    padding-top: 0.5;
    padding-left: 0.5;
    font-size: $base-font;
  }

  &__buttons-container {
    display: flex;
    justify-content: flex-end;
  }

  &__background {
    position: absolute;
    z-index: 4;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.5);
  }
}
