.sign-in {
  .divider-totp {
    height: 2px;
    background: linear-gradient(
      270deg,
      rgba(238, 238, 238, 0) 0%,
      rgba(216, 216, 216, 0.2) 53.67%,
      rgba(216, 216, 216, 0) 100%
    );
    margin-top: 24px;
    margin-bottom: 24px;
  }

  label {
    opacity: 0.5;
    color: #ffffff;
    font-size: 13px;
    margin-bottom: 7px;
  }

  &__code {
    opacity: 0.8 !important;
  }

  &__sign-up-link {
    margin-left: 3px;
    text-decoration: underline;
    color: #aed7e8;
  }

  &__sign-up-link {
    color: $paradigmLightBlue;
  }

  &__input {
    border-radius: 3px;
    color: #ffffff;
    background: $pickledBluewood;
    border-color: $pickledBluewood;
    font-size: 15px;
    height: 40px;
    &:focus {
      background: $pickledBluewood;
    }
  }

  input::placeholder {
    color: white !important;
    font-weight: 500 !important;
  }

  &__link {
    text-decoration: none;
    font-size: $small-font;
    color: $paradigmLightBlue;
  }

  &__welcome {
    font-size: 21px;
    margin-bottom: 8px;
    font-weight: bold;
    color: white;
  }

  button {
    padding-top: 3px;
    padding-bottom: 3px;
    height: 30px;
  }
}
