.header {
  height: 50px;
  //z-index: 1;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.3);
  background-color: #0a1523;
  padding-left: 14px;
  padding-right: 10px;
  display: flex;
  align-items: center;

  .divide-left {
    border-left: solid 1px $border-right;
  }

  .bottom-row {
    margin-left: 18px;
  }

  .channel-closed {
    padding: 1px 6px;
    background: #0f2038;
    border-radius: 3px;
    font-size: 12px;
  }

  .status {
    @include same-size(8px);
    border-radius: 50%;
    display: flex;
    position: relative;
    top: 1px;
  }

  .online {
    background-color: $lima;
  }

  .offline {
    background-color: $grey-color;
  }

  &__status-label {
    margin-bottom: 0;
    font-size: 11px;
    opacity: 0.7;
  }

  &__members-count-double {
    padding-left: 5px !important;
    padding-right: 4px !important;
    padding-bottom: 14px !important;
    padding-top: 3px !important;
    margin-left: 10px;
    font-size: 11px;
  }

  &__members-count-single {
    padding-left: 5px !important;
    padding-right: 4px !important;
    padding-bottom: 14px !important;
    padding-top: 3px !important;
    margin-left: 10px;
    font-size: 11px;
  }
}

.legs-table {
  input {
    width: 60px;
    background-color: transparent;
    border: none;
    &:not(:disabled) {
      border-bottom: 1px solid $paradigmLightBlue;
    }
    &.error {
      border-bottom: 1px solid $maroonFlush;
    }
    outline: none;
    color: white;
    font-size: 12px;
    padding: 0 4px;
    &:focus {
      background-color: $paradigmBlue;
    }
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}

.requested-action {
  .inputs-wrapper {
    background: $pickledBluewood;
    padding: 2px 4px;
    height: 32px;
    font-size: 13px;
    border-radius: 3px;
    &.disabled {
      opacity: 0.6;
    }
  }
  input {
    width: 42%;
    background-color: transparent;
    border: none;
    outline: none;
    color: white;
    font-size: 14px;
    padding: 3px 4px;
  }
  .buttons {
    button {
      font-size: 12px;
      height: 30px;
    }
  }
  .quoting-type-button-wrapper {
    background: $pickledBluewood;
    border-radius: 10px;
    height: 20px;
    width: 100%;
    margin-bottom: 16px;
  }
  .quoting-type-button {
    color: white;
    box-shadow: none;
    width: 50%;
    height: 20px;
    border-radius: 10px;
    font-size: 12px;
    line-height: 1;
    border: none;
    padding: 0;
    position: relative;
    top: -2px;
  }
}

.expired-action,
.canceled-action,
.quoted-action,
.expired-action,
.filled-action {
  font-size: 12px;
  button {
    font-size: 12px;
    height: 30px;
  }
}

.details-trigger {
  display: inline-block;
  color: $paradigmLightBlue;
  cursor: pointer;
  font-size: 12px;
  font-weight: normal;
  &:hover {
    color: darken($paradigmLightBlue, 8%);
  }
  i {
    margin-left: 2px;
    font-size: 10px;
    position: relative;
    top: -2px;
  }
  @media screen and (min-width: 1000px) {
    margin-left: 20px !important;
  }
}

.list-counterparties {
  color: white;
  margin: 0 -16px 8px;
  padding: 8px 16px;

  &::before {
    left: -2px;
    top: -18px;
    content: ' ';
    border: 10px solid rgba(136, 183, 213, 0);
    height: 0;
    width: 0;
    border-bottom-color: #0f2038;
    position: absolute;
    pointer-events: none;
    z-index: 1;
  }

  .small-text {
    font-size: 10px;
    color: $nepal;
  }
}

.execute {
  color: white;

  &-sell {
    background-color: $razzmatazz;
  }
  &-buy {
    background-color: $lima;
  }
}

.trading-bot {
  color: white;

  .spinning-circle {
    position: absolute;
    top: 30px;
    right: 0;
    &:after {
      background: #0f2038;
    }
  }

  @media screen and (max-width: 800px) {
    .trade-maker-ids-container {
      position: absolute;
      right: 0;
      bottom: -21px;
    }
  }

  .trading-bot-header {
    display: flex;
    justify-content: space-between;
    color: #2e77e2;
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 0.25rem;

    @media screen and (max-width: 800px) {
      & {
        position: relative;
      }
    }
  }

  .trade-badge {
    float: right;
    color: white;
    margin-left: 8px;
    font-size: 11px !important;
    line-height: 1.8;
  }

  .small-text {
    font-size: 10px;
    color: $nepal;
  }

  .actions {
    margin-left: 34px;
  }

  &-progress {
    border-radius: 40px 40px 0 0;
    overflow: hidden;
    position: relative;
    left: -7px;
    top: -6px;
    width: calc(100% + 14px);
    height: 5px !important;
    z-index: 2;

    progress {
      width: calc(100% + 8px);
      position: absolute;
    }
  }

  .message__chat-msg {
    font-size: 13px;
  }
}

.trade-box {
  background: $firefly;
  position: relative;
  border-radius: 3px;

  &:not(.isFilled)::after {
    left: 100%;
    top: 10px;
    content: ' ';
    border: 10px solid rgba(136, 183, 213, 0);
    height: 0;
    width: 0;
    border-left-color: $firefly;
    position: absolute;
    pointer-events: none;
  }
  &:not(.canTrade)::after {
    top: calc(50% - 10px);
  }
  &.canTrade::before {
    left: 100%;
    top: 42px;
    content: ' ';
    border: 10px solid rgba(136, 183, 213, 0);
    height: 0;
    width: 0;
    border-left-color: $pickledBluewood;
    position: absolute;
    pointer-events: none;
  }
  &.canTrade.custom::before {
    top: 50px;
  }

  .trade-item {
    &.sell,
    &.sell button {
      color: $maroonFlush;

      &.maker {
        color: $lima;
      }
    }

    &.buy,
    &.buy button {
      color: $lima;

      &.maker {
        color: $maroonFlush;
      }
    }
  }
}

.message {
  &__header-spacing {
    //margin-top: 2rem !important;
  }

  &__user-reply {
    color: #2e77e2;
    font-size: 12px;
    font-weight: 500;

    .entity-name {
      font-size: 12px;
      color: white;
      opacity: 0.5;
      font-weight: 300;
    }
  }

  &__hour {
    color: $nepal;
    font-size: $xs-font;
    margin-left: 10px;
    line-height: 1;
    margin-bottom: -1px;
    margin-top: 2px;
  }

  &__user-title {
    color: #bee1ef;
    font-size: $smaller-font;
    font-weight: 500;
    margin-bottom: 4px;
  }

  &__chat-msg {
    font-family: 'IBM Plex Sans';
    font-size: 14px;
    font-weight: 300;
    color: white;
    // so messages with long words are wrapped into the next line
    // TODO: make this cleaner.
    width: auto;
    overflow-wrap: anywhere;
    white-space: pre-wrap;
    word-break: break-word;

    .re-send {
      font-size: 12px;
      position: relative;

      .text,
      .separator {
        opacity: 0.6;
      }

      .btn-link {
        color: $second-user-color;
        transition: color 0.2s ease-in-out;
        cursor: pointer;
        opacity: 1;
        font-size: inherit;
        padding: 0 4px;

        &:hover {
          color: $user-hover-color;
          text-decoration: none;
        }
      }

      &::before {
        content: '';
        width: 3px;
        height: 14px;
        background: grey;
        position: absolute;
        top: 2px;
        border-radius: 25%;
      }
    }
  }

  &__alert-msg {
    white-space: normal;
    background: #203242 !important;
    padding: 2px 12px;
    border-radius: 12px;
    margin: 0 auto;
    font-size: $tiny-font;
    word-break: break-word;
  }
}

.chat {
  min-width: 0;
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: $firefly;
  height: 100%;

  .scroll-bottom {
    width: 50px;
    height: 50px;
    box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.15);
    position: sticky;
    left: calc(100% - 110px);
    z-index: 999;
    bottom: 30px;
    background: #152333;
    border-radius: 50%;
    display: none;
    cursor: pointer;

    .number {
      width: 29px;
      height: 29px;
      box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.15);
      background-color: #75abf2;
      border-radius: 50%;
      position: absolute;
      top: -16px;
      left: calc(50% - 14px);
      display: flex;
      font-size: 11px;

      span {
        margin: auto;
      }
    }

    &:hover {
      background: #0e1823;
    }

    &.visible {
      display: flex !important;
    }

    i {
      margin: auto;
      font-size: 26px;
    }
  }

  &__messages-wrapper {
    flex: 1;
    overflow: auto;
    overflow: overlay; // works on chrome, will behave like auto on other browsers
    visibility: hidden;
    transition: visibility 0.2s;

    &:hover {
      transition: visibility 0s 0.2s;
    }

    &:hover,
    &:focus {
      visibility: visible;
    }
  }

  &__messages-container {
    padding-left: 16px;
    padding-right: 16px;
    visibility: visible;
  }

  &__date-wrapper {
    position: sticky;
    top: 0;
    margin-bottom: 8px;
    z-index: 3;

    &:after {
      content: '';
      position: absolute;
      background: linear-gradient(
        to right,
        rgba(188, 188, 188, 0),
        rgba(188, 188, 188, 0.2),
        rgba(188, 188, 188, 0)
      );
      width: 50%;
      height: 2px;
      left: 25%;
      top: 10px;
      z-index: 2;
    }
  }

  &__date-badge {
    background-color: $firefly;
    padding: 5px 14px;
    color: $nepal;
    margin: auto;
    z-index: 3;
  }

  &__trade-closed {
    flex: 0 0 60px;
    background: $mirage;
    width: 100%;
  }

  &__message-box {
    flex: 0 0 112px;
    align-items: center;
    background-color: $msg-box;
    width: 100%;
    height: 112px;
    padding: 8px;
  }

  &__inside-msg-box {
    display: flex;
    border-radius: 2px;
    background-color: $firefly;
    width: 100%;
    height: 100%;

    .suggestions {
      .suggestion-item {
        &:hover {
          cursor: pointer;
          background: #222e3f;
        }
      }
    }

    &.disabled {
      background-color: $disabled-color !important;
      color: $disabled-font-color !important;
    }
  }

  &__textarea-container {
    display: flex !important;
    width: 100%;
  }

  &__msg-area {
    padding-left: 8px;
    border: 0;
    background-color: #0a1523;
    width: 100%;
    color: $chat-msg-color;
    resize: none;
    font-size: $msg-font;
    font-weight: 300;

    &:disabled {
      background-color: $disabled-color !important;
      color: $disabled-font-color !important;
      user-select: none !important;
      pointer-events: none !important;
    }
  }

  &__msg-area:focus {
    outline-style: none;
  }

  &__empty-area {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $firefly;
    width: 100%;
    min-height: 100%;
  }

  &__empty-area-badge {
    padding: 4px 10px;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 10rem;
    min-width: 253px;
  }
}

.group-info {
  &__name {
    font-size: 20px;
    @extend .text-truncate;

    &__input {
      @extend .search-input;
      width: 100px !important;
      font-size: 18px;
      font-weight: 500;
    }
  }

  &__close {
    color: #fff !important;
  }

  &__background {
    position: absolute;
    z-index: 2;
    height: 100%;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.5);
  }

  &__grey-icon {
    width: 22px;
    height: 22px;
    color: #fff !important;
    text-shadow: 0 1px 0 #fff;
    opacity: 0.5;
  }

  &__grey-icon:hover {
    opacity: 0.8 !important;
  }

  &__grey-icon-sm {
    font-size: 14px !important;
    color: #fff !important;
    text-shadow: 0 1px 0 #fff;
    opacity: 0.5;
    margin-bottom: 10px;
  }

  &__grey-icon-sm:hover {
    opacity: 0.8 !important;
  }

  .highlighted {
    color: $second-user-color;
    margin: 0;
    padding: 0;
    background: transparent;
  }

  &__grey-ellipsis {
    color: #fff !important;
    text-shadow: 0 1px 0 #fff;
    opacity: 0.5;
    font-size: 20px !important;
  }

  &__mini-icon-clear {
    color: #fff;
    font-size: 11px !important;
    display: flex;
    justify-content: center;
  }

  &__grey-ellipsis:hover {
    opacity: 0.8 !important;
  }

  &__admin-badge {
    border: none;
    border-radius: 3px;
    background-color: $paradigmLightBlue;
    cursor: default;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    height: 18px;
    color: #fff;
    font-size: $tiny-font;
  }

  &__selected-label {
    color: white;
    font-size: 18px;
    margin-bottom: 0;
  }

  &__search {
    width: 80%;
    height: 34px;
    border-style: none;
    background-color: #021933;
    padding: 8px 5px 8px 5px;
    color: #fff;
    font-size: 13px;
  }

  &__search:focus {
    outline-style: none;
  }

  &__search::placeholder {
    color: $nepal;
    font-size: $base-font;
  }

  &__search-icon-container {
    width: 10%;
    height: 34px;
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
    background-color: #021933;
  }

  &__search-clear-icon-container {
    width: 10%;
    height: 34px;
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
    background-color: #021933;
  }

  &__search-clear-icon {
    color: white;
    font-size: 20px;
    object-fit: contain;
    margin-top: 1px;
    cursor: pointer;
  }

  &__search-icon {
    color: white;
    font-size: 14px;
    object-fit: contain;
  }

  &__users-container {
    overflow-x: hidden;
    overflow-y: scroll;
    overflow-y: overlay;
    max-height: calc(100vh - 474px);
  }
  &__users-sub-container {
    overflow-x: hidden;
    overflow-y: scroll;
    overflow-y: overlay;
    max-height: calc((100vh - 528px) / 2);
  }

  &__user-container:hover {
    background-color: $pickledBluewood;
  }

  &__user-container:hover div span,
  &__user-container:hover div i,
  &__user-container:hover div button {
    display: block !important;
  }
}
